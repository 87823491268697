<template>
    <div></div>
</template>

<script>
  import { resetPwd } from '@/api/system/user'
  export default {
    name: 'resetPass',
    created(){
      let params = this.$route.query
      resetPwd(params).then((res) => {
          this.$router.push({
            path:'/login'
          })
        });

    },
  }
</script>

<style scoped>

</style>
